<template>
  <div id="process-bar-profile">
    <div class="row">
      <div class="progressbar-wrapper f-maru">
        <ul class="progressbar">
          <li
            :class="
              processStep === 1 || processStep === 2 || processStep === 3
                ? 'active'
                : ''
            "
          ></li>
          <li
            :class="processStep === 2 || processStep === 3 ? 'active' : ''"
          ></li>
          <li :class="processStep === 3 ? 'active step_three' : ''"></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProcessBarProfile",
  props: {
    processStep: {
      type: Number
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/processBarProfile.scss";
</style>
