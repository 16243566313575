<template>
  <div id="invite-friend">
    <AppContainer :useBackground="true" :useShadow="true">
      <div class="text-center prego f-w3 card">
        <div class="process-invite">
          <process-bar-profile :processStep="processStep" />
        </div>
        <img
          src="@/assets/image/home_top/avatar_prego.svg"
          alt=""
          class="logo-prego"
        />
        <p class="f-w6 text-prego">LINEアカウント</p>
        <!--      <p class="f-w3 text-note">友だち数 200</p>-->
        <p class="text-invite-friend">次回からLINEでログインできます♪</p>

        <div class="justify-content-center btn-invite">
          <button class="invite-friend" @click="addFriendPrego()">
            <img
              src="@/assets/image/home_top/icon_add_friend.svg"
              class="add-friend"
              alt=""
            />
            追加
          </button>
        </div>
      </div>
    </AppContainer>
  </div>
</template>

<script>
import ProcessBarProfile from "@/components/ProcessBarProfile";
export default {
  components: {
    "process-bar-profile": ProcessBarProfile
  },
  data() {
    return {
      processStep: 3
    };
  },
  mounted() {
    let layout = document.getElementById("layout-default");
    layout.style.backgroundImage =
      "url(" + require("@/assets/image/background/bgr-pc.png") + ")";
    let tbEl = document.getElementById("invite-friend");
    tbEl.scrollIntoView({ block: "start" });
    scroll.style.overflowY = "scroll";
  },
  destroyed() {
    let layout = document.getElementById("layout-default");
    layout.style.backgroundImage =
      "url(" + require("@/assets/image/background/order_list_bg.png") + ")";
  },
  methods: {
    async addFriendPrego() {
      this.$emit("addFriendPrego");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_fontFamily.scss";
#invite-friend {
  /deep/ .app-body._header .app-container__content {
    padding: 0;
    background-image: url("~@/assets/image/icon_profile/profile-bgr-sm.png");
    z-index: 10;
  }

  .card {
    //margin-left: 120px;
    //margin-right: 120px;
  }
  .prego {
    border-radius: 0px;
  }

  @media screen and (max-width: 600px) {
    .card {
      margin: 15px;
    }
    .prego {
      border-radius: 5px;
    }

    .logo-prego {
      margin-top: 105px;
    }
  }
}
.prego {
  height: 100vh;
}
.logo-prego {
  margin-top: 62px;
  margin-bottom: 15px;
}
.text-prego {
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 4px;
}
.text-note {
  font-size: 14px;
  line-height: 21px;
  color: #9f9f9f;
  margin-bottom: 15px;
}
.text-invite-friend {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 45px;
}
.reset-invite-friend {
  box-shadow: none;
  outline: none;
  border-radius: 5px;
  color: #adabab;
  font-size: 16px;
  width: 163px;
  height: 45px;
  background-color: #ffffff;
  //margin-left: 11px;
}
.invite-friend {
  box-shadow: none;
  outline: none;
  border-radius: 5px;
  color: #ffffff;
  font-size: 16px;
  width: 163px;
  height: 45px;
  background-color: #464d77;
  .add-friend {
    width: 18px;
    height: 20px;
    margin-right: 10px;
    margin-bottom: 5px;
  }
}

.btn-invite {
  display: grid;
}

.process-invite {
  margin-top: 20px;
}

@media screen and (min-width: 1080px) {
  .logo-prego {
    width: 130px;
    height: 130px;
    margin-top: 190px;
    margin-bottom: 20px;
    margin-left: 44%;
  }
  .text-prego {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 6px;
  }
  .text-note {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .text-invite-friend {
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 50px;
  }
  .reset-invite-friend {
    width: 210px;
    height: 55px;
    font-size: 20px;
    //margin-left: 20px;
  }
  .invite-friend {
    width: 210px;
    height: 55px;
    font-size: 20px;
    .add-friend {
      width: 22px;
      height: 25px;
    }
  }
}
@media screen and (max-width: 1280px) {
  .logo-prego {
    margin-top: 150px;
  }
}
@media screen and (max-width: 1079px) {
  .logo-prego {
    width: 105px;
    height: 105px;
    margin-left: 44%;
  }
}
@media screen and (max-width: 730px) {
  .logo-prego {
    width: 105px;
    height: 105px;
    margin-left: 42%;
  }
}
@media screen and (max-width: 600px) {
  .card {
    margin: 15px;
  }
  .prego {
    border-radius: 5px;
  }
  .logo-prego {
    width: 105px;
    height: 105px;
    margin-left: 40%;
  }
}
@media screen and (max-width: 420px) {
  .logo-prego {
    width: 105px;
    height: 105px;
    margin-left: 37%;
  }
}
@media screen and (max-width: 370px) {
  .logo-prego {
    width: 105px;
    height: 105px;
    margin-left: 35%;
  }
}
</style>
