<template>
  <div id="avatar-men">
    <!--    <AppContainer :useBackground="true">-->
    <div class="tutorial-notification-image d-flex justify-content-center">
      <template>
        <div class="content">
          <div
            class="text-left"
            @click="$emit('back-tutorial')"
            v-if="auth.login_type == 'LINE'"
          >
            <img
              class="btn-back"
              :src="require('@/assets/image/arrow-right-color.svg')"
              alt=""
            />
          </div>
          <process-bar-profile :processStep="processStep" />
          <div class="upload-img-title f-w6">
            <p>さあ、始めましょう！</p>
          </div>
          <div class="text text-top f-w3">
            Pregoを通じて「あなたに合った」<br />打ちっぱなし・ラウンド相手を見つけましょう
          </div>

          <div class="tutorial-notification__div f-maru introduce__img">
            <div class="tutorial-welcome">
              <div
                class="
                d-flex
                justify-content-center
                position-relative
                option
              "
              >
                <div class="content--checkbox f-maru">
                  <div class="text-left div-info">
                    <div class="text text-middle f-w6">
                      <img
                        :src="
                          require('@/assets/image/icon_profile/check-solid.svg')
                        "
                        alt=""
                      />
                      毎月<span class="spe">100名以上</span>が登録<span
                        class="small"
                        >※</span
                      >
                    </div>
                    <div class="text text-middle f-w6">
                      <img
                        :src="
                          require('@/assets/image/icon_profile/check-solid.svg')
                        "
                        alt=""
                      />
                      毎月<span class="spe">5,000件</span>のやりとり<span
                        class="small"
                        >※</span
                      >
                    </div>
                    <div class="text text-middle f-w6">
                      <img
                        :src="
                          require('@/assets/image/icon_profile/check-solid.svg')
                        "
                        alt=""
                      />
                      登録は<span class="spe">完全無料</span>です
                    </div>
                    <div class="text text-center f-w3">
                      <span class="small">※</span
                      >月間の登録会員数を日数でわって算出（2023年6月時点）
                    </div>
                  </div>
                  <div class="d-flex position-relative align-items-center">
                    <div
                      class="checkbox d-flex justify-content-center align-items-center checkbox-law"
                      id="checkbox"
                      :class="checkedLaw ? 'active' : ''"
                      @click="checkedLaw = !checkedLaw"
                    >
                      <b-icon icon="check" v-if="checkedLaw" />
                    </div>
                    <span class="text text--bottom">
                      <span class="underline cursor-pointer" @click="showLaw()"
                        >利用規約</span
                      >
                      、<span
                        class="underline cursor-pointer"
                        @click="nextPolicy()"
                        >プライバシーポリシー</span
                      >に同意する
                    </span>
                  </div>
                  <div
                    class="d-flex position-relative align-items-center top age-acc"
                  >
                    <div
                      class="checkbox d-flex justify-content-center align-items-center"
                      id="checkbox"
                      :class="checkedAge ? 'active' : ''"
                      @click="checkedAge = !checkedAge"
                    >
                      <b-icon icon="check" v-if="checkedAge" />
                    </div>
                    <span class="text">
                      20歳以上です
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <button
              class="text-center position-relative snap f-maru"
              @click="handleSubmit"
              :disabled="!checkedLaw || !checkedAge"
            >
              次へ
              <img
                src="@/assets/image/icon-next.svg"
                class="position-absolute"
                alt=""
                id="icon"
              />
            </button>
          </div>
        </div>
      </template>
    </div>
    <!--    </AppContainer>-->
  </div>
</template>

<script>
import "vue-advanced-cropper/dist/style.css";
import { mapGetters } from "vuex";
import ProcessBarProfile from "@/components/ProcessBarProfile";

export default {
  name: "TutorialNotificationImage",
  components: {
    "process-bar-profile": ProcessBarProfile
  },
  props: {
    propsSex: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      propsUser: {
        image_url: null,
        rank: null
      },
      checkedAge: false,
      checkedLaw: false,
      processStep: 2
    };
  },
  created() {
    this.$root.$refs.loading.start();
  },
  mounted() {
    this.$root.$refs.loading.finish();
  },
  computed: {
    ...mapGetters({
      auth: "auth/user"
    })
  },
  methods: {
    showLaw() {
      if (this.propsSex == 1) {
        this.$router.push({
          name: "MenTermOfUse",
          query: {
            policy: "success"
          }
        });
        return;
      }
      this.$router.push({
        name: "CastTermOfUse",
        query: {
          policy: "success"
        }
      });
    },
    nextPolicy() {
      if (this.propsSex == 1) {
        this.$router.push({
          name: "MenPolicy",
          query: {
            policy: "success"
          }
        });
        return;
      }
      this.$router.push({
        name: "CastPolicy",
        query: {
          policy: "success"
        }
      });
    },
    handleSubmit() {
      this.$emit("setStep");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/men/tutorial/tutorialNotificationImage";
@import "@/assets/scss/_fontFamily.scss";
.video {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  max-width: 1080px;
}
#avatar-men {
  /deep/ .app-body._header .app-container__content {
    padding: 0;
    //background-image: url("~@/assets/image/icon_profile/profile-bgr-l.png");
  }
  background-image: url("~@/assets/image/background/bgr-pc_50.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 100%;
  .underline {
    text-decoration: underline;
  }
  .upload-img-title {
    font-size: 25px;
    margin-top: 25px;
    font-weight: 600;
    color: #ffffff;
  }

  .tutorial-notification-image {
    overflow: auto !important;
    @media screen and (max-width: 1280px) {
      .tutorial-notification__div {
        height: 588px;
      }
    }
  }
  .introduce__img {
    color: #ffffff;
  }

  .golf-user {
    img {
      width: 430px;
      height: 430px;
    }
  }

  .tutorial-welcome {
    .disabled {
      opacity: 0.4;
      pointer-events: none;
    }
    .btn-back {
      position: absolute;
      top: 10px;
      left: 19px;
      cursor: pointer;
    }
    .image-welcome {
      height: 261px;
      background-image: url("~@/assets/image/icon_mypage/welcome-tutorial-pc.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .content {
      padding: 23px 26px 24px 26px;
      color: #1a1311;
      .logo-prego {
        width: 140px;
        height: 35px;
        float: left;
      }
      .welcome-text {
        margin-left: 6px;
        font-size: 15px;
        line-height: 15px;
      }
      &__law {
        margin-top: 34px;
        &--text {
          font-size: 14px;
          text-align: left;
          margin-bottom: 17px;
          &-last {
            font-size: 18px;
          }
          &.--bottom {
            margin-top: 5px;
            margin-bottom: 54px;
          }
        }
      }
      .top {
        margin-bottom: 32px;
        margin-top: 34px;
      }
      &--checkbox {
        width: 430px;
        @media screen and (max-width: 600px) {
          width: 315px;
        }
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 30px;
        .checkbox {
          width: 24px;
          border-radius: 4px;
          height: 24px;
          @media screen and (max-width: 600px) {
            width: 18px;
            height: 18px;
          }
          margin-right: 13px;
          border: 1px solid #b9b9b9;
          border-top: none;
          border-left: none;
          color: $white;
          background-color: #ffffff;
          &.active {
            background-color: #464d77;
          }
          &-law {
            margin-bottom: 0;
            margin-right: 12px;
          }
        }
        .text {
          font-size: 19px;
          @media screen and (max-width: 600px) {
            font-size: 14px;
          }
          line-height: 13px;
          bottom: 32px;
          left: 30px;
          &--bottom {
            bottom: 0;
          }
          &--blue {
            color: #75c4cc;
            font-size: 15px;
            line-height: 15px;
            &.text-law {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
#video {
  margin-top: 50px;
}
.snap {
  width: 430px;
  height: 50px;
  font-size: 20px;
  background-color: #464d77;
  margin: auto;
  border-radius: 4.1px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 20px;
}
.snap:disabled {
  opacity: 0.7;
}
#icon {
  width: 10.77px;
  height: 20px;
  right: 17px;
}
#cancel {
  height: 50px;
  width: 210px;
  margin-right: 20px;
  font-size: 16px;
  outline: none;
  box-shadow: none;
  border-radius: 5px;
  background-color: #dbdbdb;
  color: #000000;
}
#snap {
  height: 55px;
  width: 210px;
  font-size: 16px;
  outline: none;
  box-shadow: none;
  border-radius: 5px;
  background-color: #464d77;
  color: #ffffff;
}
</style>

<style lang="scss" scoped>
#avatar-men {
  /deep/.edit-profile-vue-cropper {
    position: fixed;
    top: 0px !important;
    bottom: 0;
    width: 100%;
    z-index: 12 !important;
    background-color: #fff;
    max-width: 1080px;
    .upload-example-cropper {
      height: calc(100% - 90px);
    }
    .btn-cancel {
      background-color: #dbdbdb;
      border-radius: 5px;
      height: 45px;
      width: 163px;
      margin-right: 11px;
      .btn {
        width: 100%;
        height: 100%;
      }
    }
    .btn-success {
      background-color: #464d77;
      border-radius: 5px;
      height: 45px;
      width: 163px;
      .btn {
        color: #fff;
        width: 100%;
        height: 100%;
      }
    }
    .btn-option {
      background-color: white;
      position: relative;
      z-index: 10;
      padding: 20px 0;
    }
  }

  .text-top {
    color: white;
    font-size: 14px !important;
    @media screen and (max-width: 600px) {
      font-size: 14px !important;
      line-height: 20px !important;
    }
  }

  .div-info {
    margin-top: 60px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .text {
      font-size: 14px !important;
      padding-top: 5px;
      @media screen and (max-width: 600px) {
        font-size: 14px !important;
        line-height: 20px !important;
      }
    }
    .text-middle {
      letter-spacing: 2px;
      font-size: 30px !important;
      font-weight: bold;
      color: white;
      .spe {
        color: #18f718;
      }
      @media screen and (max-width: 600px) {
        letter-spacing: 0px;
        font-size: 24px !important;
      }
    }
    .small {
      font-size: 12px;
      font-weight: 400;
      @media screen and (max-width: 600px) {
        font-size: 10px;
      }
    }
  }
}
@media screen and (min-width: 1080px) {
  #avatar-men {
    /deep/.edit-profile-vue-cropper {
      top: 0 !important;
      .upload-example-cropper {
        height: calc(100% - 173px);
      }
      .btn-cancel {
        height: 55px;
        width: 210px;
        margin-right: 20px;
        font-size: 20px;
      }
      .btn-success {
        height: 55px;
        width: 210px;
        font-size: 20px;
      }
      .btn-option {
        padding: 35px 0;
      }
    }
  }
}

@media screen and (max-width: 1079px) {
  #avatar-men {
    .upload-img-title {
      font-size: 20px;
      margin-bottom: -10px;
    }
    .snap {
      width: 330px;
      height: 45px;
      font-size: 17px;
    }

    .btn-choose-img {
      width: 330px;
    }

    .button-back {
      width: 330px !important;
    }

    .golf-user {
      img {
        width: 340px;
        height: 340px;
      }
    }
    .tutorial-notification__div {
      .label--top {
        margin-top: 10px;
      }
    }
    .btn-back {
      width: 23px;
      height: 40px;
    }
  }
}

@media screen and (max-width: 600px) {
  #avatar-men {
    background-image: url("~@/assets/image/icon_profile/profile-bgr-sm.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: inherit;
    .snap {
      width: 315px;
      height: 45px;
      font-size: 16px;
    }
    .btn-choose-img {
      width: 315px !important;
    }

    .button-back {
      width: 315px !important;
    }

    .golf-user {
      img {
        width: 315px;
        height: 315px;
      }
    }

    .btn-back {
      width: 14px;
      height: 23px;
    }

    .content {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

@media screen and (max-width: 325px) {
  #avatar-men {
    .snap {
      width: 255px;
      height: 45px;
    }

    .btn-choose-img {
      width: 255px !important;
    }

    .button-back {
      width: 255px !important;
    }

    .golf-user {
      img {
        width: 255px;
        height: 255px;
      }
    }
  }
}
</style>
