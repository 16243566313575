var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"first-step-register"}},[_c('AppContainer',{attrs:{"useBackground":true}},[_c('div',{staticClass:"tutorial-basic-info f-maru"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"tutorial-notification__div card"},[_c('div',{staticClass:"process-bar-info"},[_c('process-bar-profile',{attrs:{"processStep":_vm.processStep}})],1),_c('p',{staticClass:"text-address f-w6"},[_vm._v(" プロフィールを登録しましょう！ ")]),_c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('ValidationProvider',{attrs:{"name":"性別","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"tutorial-welcome"},[_c('div',{staticClass:"\n                    text-left\n                    justify-content-center\n                    position-relative\n                    option\n                  "},[_c('p',{staticClass:"label f-maru"},[_c('span',[_vm._v("性別")]),_c('span',{staticClass:"requied-cl"},[_vm._v(" ※必須")])]),_c('div',{staticClass:"d-flex d-gender"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sex),expression:"sex"}],attrs:{"type":"radio","id":"male","value":"1"},domProps:{"checked":_vm._q(_vm.sex,"1")},on:{"change":function($event){_vm.sex="1"}}}),_c('label',{staticClass:"f-w3 left",attrs:{"for":"male"}},[_vm._v("男性")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sex),expression:"sex"}],attrs:{"type":"radio","id":"female","value":"2"},domProps:{"checked":_vm._q(_vm.sex,"2")},on:{"change":function($event){_vm.sex="2"}}}),_c('label',{staticClass:"f-w3",attrs:{"for":"female"}},[_vm._v("女性")]),_c('br')]),_c('span',{staticClass:"error text-left"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"ニックネーム","rules":"required|min:1|max:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"\n                    text-left\n                    justify-content-center\n                    position-relative\n                    option\n                  "},[_c('p',{staticClass:"label f-maru"},[_c('span',[_vm._v("ニックネーム")]),_c('span',{staticClass:"requied-cl"},[_vm._v(" ※必須")])]),_c('div',{staticClass:"input-option"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.nickname),expression:"nickname"}],staticClass:"w-100",attrs:{"type":"text"},domProps:{"value":(_vm.nickname)},on:{"blur":function($event){return _vm.handleBlurNickname()},"keyup":_vm.changeNickName,"input":function($event){if($event.target.composing){ return; }_vm.nickname=$event.target.value}}})]),_c('span',{staticClass:"error text-left"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('div',{staticClass:"text-left justify-content-center position-relative option"},_vm._l((_vm.select),function(select,index){return _c('div',{key:index,staticClass:"position-relative point",class:select.class},[_c('p',{staticClass:"label f-maru",class:index == 1 ? 'label--bottom' : ''},[_c('span',[_vm._v(_vm._s(select.name))]),_c('span',{staticClass:"requied-cl"},[_vm._v(" ※必須")])]),_c('div',{staticClass:"focus-div",attrs:{"id":index == 0 ? 'best-score' : 'avg-score'}},[_c('div',{staticClass:"\n                    position-relative\n                    d-flex\n                    align-items-center\n                    button-option\n                  ",class:select.isShow ? 'active' : '',on:{"click":function($event){return _vm.showOptionPoint(index, select.name)}}},[_c('div',{staticClass:"w-100 input d-flex align-items-center",attrs:{"type":""}},[_vm._v(" "+_vm._s(select.value)+" ")])]),_c('transition',{attrs:{"name":"slide"}},[(select.isShow)?_c('div',{staticClass:"options"},_vm._l((select.options),function(option,indexOption){return _c('p',{key:indexOption,class:select.name,on:{"click":function($event){return _vm.selectOptionPoint(
                              index,
                              select.options[indexOption]
                            )}}},[_vm._v(" "+_vm._s(select.options[indexOption])+" ")])}),0):_vm._e()])],1),_c('span',{staticClass:"error"},[_vm._v(_vm._s(select.error))])])}),0),_c('div',{staticClass:"d-flex edit text-left justify-content-center option row box-date"},_vm._l((_vm.text),function(text,index){return _c('div',{key:index,staticClass:"position-relative date-padding",class:{
                    disabled:
                      (index == 1 && !_vm.showMonth) || (index == 2 && !_vm.showDay)
                  }},[_c('p',{staticClass:"text-year f-maru"},[_vm._v(_vm._s(text.name))]),_c('ValidationProvider',{attrs:{"name":text.name,"rules":"required"}},[_c('div',{staticClass:"focus-div",attrs:{"id":text.name}},[_c('div',{staticClass:"\n                          position-relative\n                          d-flex\n                          align-items-center\n                          button-option\n                        ",class:text.isShow ? 'active' : '',on:{"click":function($event){return _vm.showOption(index, text.name)}}},[_c('div',{staticClass:"d-flex align-items-center w-100 input"},[_vm._v(" "+_vm._s(text.value)+" ")])]),_c('transition',{attrs:{"name":"slide"}},[(text.isShow)?_c('div',{staticClass:"options"},_vm._l((text.options),function(option,indexOption){return _c('p',{key:indexOption,class:text.name,on:{"click":function($event){return _vm.selectOption(
                                index,
                                text.options[indexOption],
                                indexOption
                              )}}},[_vm._v(" "+_vm._s(text.options[indexOption])+" ")])}),0):_vm._e()])],1),_c('span',{staticClass:"error text-left"},[_vm._v(_vm._s(text.error))])])],1)}),0),_c('div',{staticClass:"only-show-profile-text div-option text-left"},[_c('img',{attrs:{"src":require("@/assets/image/icon_profile/profile-note-icon.png"),"alt":""}}),_c('p',[_vm._v("年齢のみ表示されます。")])]),_c('div',{staticClass:"d-flex justify-content-center btn-button"},[_c('button',{staticClass:"w-100",attrs:{"type":"submit","disabled":invalid}},[_c('div',{staticClass:"\n                      position-relative\n                      d-flex\n                      align-items-center\n                      button-back\n                    "},[_c('div',{staticClass:"text-center posision-absolute w-100 text-center"},[_vm._v(" 次へ ")]),_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/image/icon-next.svg"),"alt":""}})])])])],1)]}}])})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }