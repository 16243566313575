<template>
  <div id="first-step-register">
    <AppContainer :useBackground="true">
      <div class="tutorial-basic-info f-maru">
        <div class="content">
          <div class="tutorial-notification__div card">
            <div class="process-bar-info">
              <process-bar-profile :processStep="processStep" />
            </div>
            <p class="text-address f-w6">
              プロフィールを登録しましょう！
            </p>
            <!--            <p class="text-product">残り４項目です！</p>-->
            <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form">
              <form @submit.prevent="onSubmit" class="form">
                <ValidationProvider
                  name="性別"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <div class="tutorial-welcome">
                    <div
                      class="
                      text-left
                      justify-content-center
                      position-relative
                      option
                    "
                    >
                      <p class="label f-maru">
                        <span>性別</span><span class="requied-cl"> ※必須</span>
                      </p>
                      <div class="d-flex d-gender">
                        <input type="radio" id="male" v-model="sex" value="1" />
                        <label class="f-w3 left" for="male">男性</label>
                        <input
                          type="radio"
                          id="female"
                          v-model="sex"
                          value="2"
                        />
                        <label class="f-w3" for="female">女性</label><br />
                      </div>
                      <span class="error text-left">{{ errors[0] }}</span>
                    </div>
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  name="ニックネーム"
                  rules="required|min:1|max:8"
                  v-slot="{ errors }"
                >
                  <div
                    class="
                      text-left
                      justify-content-center
                      position-relative
                      option
                    "
                  >
                    <p class="label f-maru">
                      <span>ニックネーム</span>
                      <span class="requied-cl"> ※必須</span>
                    </p>
                    <div class="input-option">
                      <input
                        type="text"
                        class="w-100"
                        v-model="nickname"
                        @blur="handleBlurNickname()"
                        @keyup="changeNickName"
                      />
                    </div>
                    <span class="error text-left">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <div
                  class="text-left justify-content-center position-relative option"
                >
                  <div
                    v-for="(select, index) in select"
                    :key="index"
                    class="position-relative point"
                    :class="select.class"
                  >
                    <p
                      class="label f-maru"
                      :class="index == 1 ? 'label--bottom' : ''"
                    >
                      <span>{{ select.name }}</span>
                      <span class="requied-cl"> ※必須</span>
                    </p>
                    <div
                      class="focus-div"
                      :id="index == 0 ? 'best-score' : 'avg-score'"
                    >
                      <div
                        class="
                      position-relative
                      d-flex
                      align-items-center
                      button-option
                    "
                        :class="select.isShow ? 'active' : ''"
                        @click="showOptionPoint(index, select.name)"
                      >
                        <div
                          type=""
                          class="w-100 input d-flex align-items-center"
                        >
                          {{ select.value }}
                        </div>
                      </div>
                      <transition name="slide">
                        <div class="options" v-if="select.isShow">
                          <p
                            :class="select.name"
                            v-for="(option, indexOption) in select.options"
                            :key="indexOption"
                            @click="
                              selectOptionPoint(
                                index,
                                select.options[indexOption]
                              )
                            "
                          >
                            {{ select.options[indexOption] }}
                          </p>
                        </div>
                      </transition>
                    </div>
                    <span class="error">{{ select.error }}</span>
                    <!-- </ValidationProvider> -->
                  </div>
                </div>
                <div
                  class="d-flex edit text-left justify-content-center option row box-date"
                >
                  <div
                    class="position-relative date-padding"
                    v-for="(text, index) in text"
                    :key="index"
                    :class="{
                      disabled:
                        (index == 1 && !showMonth) || (index == 2 && !showDay)
                    }"
                  >
                    <p class="text-year f-maru">{{ text.name }}</p>
                    <ValidationProvider :name="text.name" rules="required">
                      <div class="focus-div" :id="text.name">
                        <div
                          class="
                            position-relative
                            d-flex
                            align-items-center
                            button-option
                          "
                          :class="text.isShow ? 'active' : ''"
                          @click="showOption(index, text.name)"
                        >
                          <div class="d-flex align-items-center w-100 input">
                            {{ text.value }}
                          </div>
                        </div>
                        <transition name="slide">
                          <div class="options" v-if="text.isShow">
                            <p
                              :class="text.name"
                              v-for="(option, indexOption) in text.options"
                              :key="indexOption"
                              @click="
                                selectOption(
                                  index,
                                  text.options[indexOption],
                                  indexOption
                                )
                              "
                            >
                              {{ text.options[indexOption] }}
                            </p>
                          </div>
                        </transition>
                      </div>
                      <span class="error text-left">{{ text.error }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="only-show-profile-text div-option text-left">
                  <img
                    src="@/assets/image/icon_profile/profile-note-icon.png"
                    alt=""
                  />
                  <p>年齢のみ表示されます。</p>
                </div>
                <div class="d-flex justify-content-center btn-button">
                  <button type="submit" :disabled="invalid" class="w-100">
                    <div
                      class="
                        position-relative
                        d-flex
                        align-items-center
                        button-back
                      "
                    >
                      <div
                        class="text-center posision-absolute w-100 text-center"
                      >
                        次へ
                      </div>
                      <img
                        src="@/assets/image/icon-next.svg"
                        class="icon"
                        alt=""
                      />
                    </div>
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </AppContainer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProcessBarProfile from "@/components/ProcessBarProfile";

export default {
  name: "FirstStepRegister",
  data() {
    return {
      sex: null,
      nickname: "",
      avgScore: null,
      bestScore: null,
      select: [
        {
          name: "ベストスコア",
          value: "",
          options: [],
          isShow: false,
          error: null,
          class: "point_1"
        },
        {
          name: "アベレージスコア ",
          value: "",
          options: [],
          isShow: false,
          error: null,
          class: "point_2"
        }
      ],
      text: [
        {
          name: "年",
          value: "",
          options: [],
          isShow: false,
          error: null
        },
        {
          name: "月",
          value: "",
          options: [],
          isShow: false,
          error: null
        },
        {
          name: "日",
          value: "",
          options: [],
          isShow: false,
          error: null
        }
      ],
      year: null,
      month: null,
      day: null,
      date: null,
      showDay: false,
      showMonth: false,
      processStep: 1
    };
  },
  components: {
    "process-bar-profile": ProcessBarProfile
  },
  created() {
    var d = new Date();
    let y = d.getFullYear() - 20;
    for (let i = y; i >= y - 80; i--) {
      this.text[0].options.push(i);
    }

    for (let i = 60; i <= 160; i++) {
      this.select[0].options.push(i);
    }
    this.select[0].options.push("160以上");
    for (let i = 60; i <= 180; i += 5) {
      this.select[1].options.push(i);
    }
    this.select[1].options.push("180以上");
    let layout = document.getElementById("layout-default");
    layout.style.backgroundImage =
      "url(" + require("@/assets/image/background/bgr-pc.png") + ")";
  },
  destroyed() {
    let layout = document.getElementById("layout-default");
    layout.style.backgroundImage =
      "url(" + require("@/assets/image/background/order_list_bg.png") + ")";
  },
  computed: {
    ...mapGetters({
      auth: "auth/user"
    })
  },
  props: {
    tabindex: {
      type: Number,
      require: null,
      default: 0
    },
    propsSex: {
      type: Number,
      default: 1
    }
  },
  methods: {
    handleBlurNickname() {
      this.nickname = this.nickname.trim();
    },
    changeNickName(event) {
      this.nickname = event.target.value;
    },
    showOption(index, name) {
      this.text[index].isShow = !this.text[index].isShow;
      this.$nextTick(() => {
        let listOptions = document.getElementsByClassName(name);
        for (let i = 0; i < listOptions.length; i++) {
          if (listOptions[i].innerText == this.text[index].value) {
            listOptions[i].style.backgroundColor = "#e5e5e5";
            listOptions[i].style.color = "#000000";
          }
        }
      });
    },
    showOptionPoint(index, name) {
      this.select[index].isShow = !this.select[index].isShow;
      if (index == 0) {
        this.select[1].isShow = false;
      } else {
        this.select[0].isShow = false;
      }
      this.$nextTick(() => {
        let listOptions = document.getElementsByClassName(name);
        for (let i = 0; i < listOptions.length; i++) {
          if (listOptions[i].innerText == this.select[index].value) {
            listOptions[i].style.backgroundColor = "#e5e5e5";
            listOptions[i].style.color = "#000000";
          }
        }
      });
    },
    getDay(year, month, option) {
      if (this.text[1].value) {
        this.showDay = true;
      } else {
        this.showDay = false;
        this.text[2].value = null;
        this.day = null;
      }
      this.text[2].options = [];
      let day = new Date(year, month, 0).getDate();
      let day_now = new Date().getDate();
      let d = day;
      if (
        option == this.text[1].options.length - 1 &&
        Number(year) == Number(new Date().getFullYear()) - 20
      ) {
        d = day_now;
      }
      if (
        option == 0 &&
        Number(year) == Number(new Date().getFullYear()) - 20 &&
        Number(this.text[2].value) >= d
      ) {
        this.text[2].value = null;
      }
      for (let i = 1; i <= d; i++) {
        if (i < 10) {
          i = "0" + i;
        }
        this.text[2].options.push(i);
      }
    },
    getMonth(index) {
      (this.showMonth = true), (this.text[1].options = []);
      var d = new Date();
      let day_now = new Date().getDate();
      var m = d.getMonth();
      if (index != 0) {
        m = 11;
      }
      if (index == 0 && Number(this.text[1].value) > m) {
        this.text[1].value = null;
        this.month = null;
      }
      if (
        index == 0 &&
        Number(this.text[1].value) == m + 1 &&
        Number(this.text[2].value) > Number(day_now)
      ) {
        this.text[2].value = null;
        this.day = null;
      }
      for (let i = 1; i <= m + 1; i++) {
        if (i < 10) {
          i = "0" + i;
        }
        this.text[1].options.push(i);
      }
      this.getDay(this.text[0].value, this.text[1].value, 0);
    },
    selectOption(index, name, option) {
      this.text[index].value = name;
      this.text[index].error = null;
      if (this.text[index].name == "年") {
        this.year = name;
        this.getMonth(option);
        if (this.day > this.text[2].options[this.text[2].options.length - 1]) {
          this.text[2].value = "";
          this.day = null;
        }
      } else if (this.text[index].name == "月") {
        this.month = name;
        this.getDay(this.year, this.month, option);
        if (this.day > this.text[2].options[this.text[2].options.length - 1]) {
          this.text[2].value = "";
          this.day = null;
        }
      } else {
        this.day = name;
      }
      this.text[index].isShow = false;
    },
    selectOptionPoint(index, name) {
      this.select[index].value = name;
      this.select[index].error = null;
      if (this.select[index].name == "ベストスコア") {
        this.bestScore = name;
      } else {
        this.avgScore = name;
      }
      this.select[index].isShow = false;
    },
    onSubmit() {
      if (this.sex) {
        // point
        if (!this.bestScore) {
          this.select[0].error = this.select[0].name + "は必須項目です";
        }
        if (!this.avgScore) {
          this.select[1].error = this.select[1].name + "は必須項目です";
        }

        //date
        if (this.year == null) {
          this.text[0].error = "年は必須項目です";
        }
        if (this.month == null) {
          this.text[1].error = "月は必須項目です";
        }
        if (this.day == null) {
          this.text[2].error = "日は必須項目です";
        }
        if (
          this.year != null &&
          this.month != null &&
          this.day != null &&
          this.bestScore &&
          this.avgScore &&
          this.nickname
        ) {
          const params = {
            sex: this.sex,
            nickname: this.nickname,
            bestScore: this.bestScore,
            avgScore: this.avgScore,
            date: this.year + "-" + this.month + "-" + this.day
          };
          this.$emit("tutorialBasicInfo", params);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/men/tutorial/tutorialBasicInfo";
@import "@/assets/scss/_commons";
@import "@/assets/scss/_fontFamily";
@import "@/assets/scss/_variables";
#first-step-register {
  height: 100vh;
  /deep/ #layout-default {
    padding: 0;
    background-image: url("~@/assets/image/icon_profile/profile-bgr-sm.png");
  }
  /deep/ .app-body._header .app-container__content {
    padding: 0;
    background-image: url("~@/assets/image/icon_profile/profile-bgr-sm.png");
    overflow-y: auto;
    min-height: 100vh;
    height: 100vh;
  }
  position: relative;
  z-index: 1000;
  height: calc(100%);
  min-width: 100vw;
  .tutorial-notification__div {
    height: fit-content !important;
    min-height: 100vh;
  }

  .box-date {
    gap: 18px;
    .date-padding {
      flex: 0 0 33.333333%;
      max-width: calc(33.333333% - 12px);
    }
  }
  @media screen and (min-width: 1080px) {
    .tutorial-notification__div .option {
      max-width: 430px;
    }
    .tutorial-basic-info .content .tutorial-notification__div button {
      margin-top: 100px;
      max-width: 430px;
      height: 50px;
    }
  }
  .tutorial-basic-info {
    display: flex;
    input {
      @media screen and (min-width: 1080px) {
        height: 60px;
        font-size: 17px;
      }
    }
    .button-option {
      .input {
        @media screen and (min-width: 1080px) {
          height: 60px;
          font-size: 17px;
        }
      }
    }
    .tutorial-welcome {
      input[type="radio"] {
        height: 30px;
        width: 30px;
        accent-color: #464d77;
        @media screen and (max-width: 1079px) {
          height: 18px;
          width: 18px;
        }
      }
      label {
        font-size: 25px;
        margin-left: 16px;
        margin-top: -5px;
        @media screen and (max-width: 1079px) {
          font-size: 14px;
          margin-left: 8px;
          margin-top: -2px;
        }
      }
      label.left {
        margin-right: 100px;
      }
    }
  }
  .btn-button button:disabled {
    opacity: 0.5;
  }
  @media screen and (min-width: 1079px) {
    width: 1080px;
  }
  .card {
    background-color: #f5f5f5;
  }
  .tutorial-notification__div {
    border-radius: 0px;
    height: 100vh;
  }
  .text-address {
    font-weight: 600;
  }
  .slide-enter,
  .slide-leave-to {
    transform: scaleY(0);
  }

  .process-bar-info {
    margin-top: 20px !important;
  }

  .focus-div {
    outline: none;
    box-shadow: none;
  }
  .requied-cl {
    color: #e60013;
    font-size: 14px;
    position: relative;
    top: -3px;
  }
  .age-acc {
    margin-bottom: 30px;
    margin-top: -10px;
  }

  .warning {
    color: $red_color_error_tutorial;
    font-size: 10px;
    margin-top: 5px;
    @media screen and (min-width: 1079px) {
      font-size: 16px;
      margin-top: 16px;
    }
  }
  .text-btn {
    max-width: 334px;
  }
  .box-address {
    display: flex;
    justify-content: space-between;
    .province {
      width: 35%;
    }
    .district {
      width: 62%;
    }
  }
  .div-option {
    max-width: 334px;
    margin: auto;
    @media screen and (min-width: 1079px) {
      max-width: 430px;
    }
  }
  @media screen and (min-width: 1079px) {
    .btn-button {
      position: unset;
      top: 0;
      z-index: -10000;
      transform: unset;
      margin-top: -60px;
    }
    .text-btn {
      max-width: 430px;
    }
  }
  @media screen and (max-width: 1079px) {
    .btn-button {
      margin-top: 20px;
      //margin-left: 10px;
      //margin-right: 10px;
    }
  }

  .underline {
    text-decoration: underline;
  }

  .tutorial-welcome {
    .disabled {
      opacity: 0.4;
      pointer-events: none;
    }
    .btn-back {
      position: absolute;
      top: 10px;
      left: 19px;
      cursor: pointer;
    }
    .image-welcome {
      height: 261px;
      background-image: url("~@/assets/image/icon_mypage/welcome-tutorial-pc.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .content {
      padding: 23px 26px 24px 26px;
      color: #1a1311;
      .logo-prego {
        width: 140px;
        height: 35px;
        float: left;
      }
      .welcome-text {
        margin-left: 6px;
        font-size: 15px;
        line-height: 15px;
      }
      &__law {
        margin-top: 34px;
        &--text {
          font-size: 14px;
          text-align: left;
          margin-bottom: 17px;
          &-last {
            font-size: 18px;
          }
          &.--bottom {
            margin-top: 5px;
            margin-bottom: 54px;
          }
        }
      }
      .top {
        margin-bottom: 32px;
        margin-top: 34px;
      }
      &--checkbox {
        .checkbox {
          width: 18px;
          border-radius: 4px;
          height: 18px;
          margin-right: 13px;
          border: 1px solid #b9b9b9;
          border-top: none;
          border-left: none;
          color: $white;
          background-color: #ffffff;
          &.active {
            background-color: #464d77;
          }
          &-law {
            margin-bottom: 0;
            margin-right: 12px;
          }
        }
        .text {
          font-size: 13px;
          line-height: 13px;
          bottom: 32px;
          left: 30px;
          &--bottom {
            bottom: 0;
          }
          &--blue {
            color: #75c4cc;
            font-size: 15px;
            line-height: 15px;
            &.text-law {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .basic-info-acc {
      p {
        font-size: 15px;
      }
    }

    .card {
      margin: 30px 15px;
      border-radius: 5px;
    }

    .tutorial-notification__div {
      border-radius: 5px;
    }

    .text-year {
      font-size: 14px;
    }

    .requied-cl {
      font-size: 9px;
      top: -1px;
    }

    .form {
      padding-right: 10px;
      padding-left: 10px;
    }

    .box-address {
      margin-top: -2px;
    }

    .num-address {
      margin-top: -4px;
    }

    .point_1 {
      margin-top: 22px;

      p {
        margin-bottom: -3px;
      }
    }

    .point_2 {
      margin-top: 21px;
      p {
        margin-bottom: -4px;
      }
    }

    .date-padding {
      p {
        margin-bottom: -5px;
      }
    }

    .chome-lb {
      margin-bottom: -3px;
    }

    .other-address {
      margin-bottom: 2px;
    }

    .only-show-profile-text {
      p {
        font-size: 10px;
        padding-top: 6px;
      }
      img {
        width: 15px;
      }
    }
  }
  @media screen and (min-width: 1079px) {
    .tutorial-welcome {
      .content {
        padding: 10px 26px 25px 45px;
        &__law {
          margin-top: 27px;
          &--text {
            font-size: 23px;
            line-height: 23px;
            text-align: left;
            margin-bottom: 24px;
            &-last {
              font-size: 27px;
            }
            &.--bottom {
              margin-bottom: 37px;
            }
          }
        }
        .top {
          margin-bottom: 24px;
        }
        &--checkbox {
          .checkbox {
            width: 27px;
            height: 27px;
            margin-right: 21px;
            font-size: 20px;
            .b-icon.bi {
              width: 1.5rem;
              height: 1.5rem;
            }
            &-law {
              margin-bottom: 0;
            }
          }
          .text {
            font-size: 20px;
            line-height: 21px;
            bottom: 22px;
            left: 50px;
            &--bottom {
              bottom: -1px;
            }
            &--blue {
              font-size: 20px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
}
</style>
