<template>
  <div id="edit-profile">
    <ModalLayout ref="modalProfile">
      <p class="modal-logout-title">
        登録した情報が保存されません。それでもよろしいですか。
      </p>
      <div class="d-flex justify-content-center">
        <button
          @click.prevent="
            $router.push({ name: 'CastMyPagePersonalInformationEdit' })
          "
          class="btn-cancel-logout"
        >
          はい
        </button>
        <button @click="$refs.modalProfile.closeModal()" class="btn-logout">
          いいえ
        </button>
      </div>
    </ModalLayout>
    <div class="tutorial" v-if="step"></div>

    <tutorial-notification-image
      v-if="step == 2"
      @setStep="completeRegister"
      @back-tutorial="backTutorial"
      :propsSex="sex"
    />
    <InviteFriendLine v-if="step == 3" @addFriendPrego="addFriendPrego" />

    <tutorial-basic-info
      v-if="step == 1"
      @tutorialBasicInfo="tutorialBasicInfo($event)"
      @back-tutorial="backTutorial"
      :propsSex="user.sex"
    />
    <tutorial-edit-complete
      v-if="step == 4"
      @tutorialEditComplete="tutorialEditComplete()"
      @back-tutorial="backTutorial"
    />
  </div>
</template>

<script>
import SecondStepRegister from "@/components/User/SecondStepRegister.vue";
import FirstStepRegister from "@/components/User/FirstStepRegister.vue";
import RegisterComplete from "@/components/User/RegisterComplete.vue";
import { mapGetters } from "vuex";
import "vue-advanced-cropper/dist/style.css";
import InviteFriendLine from "@/views/Common/InviteFriendLine.vue";

export default {
  name: "EditProfile",
  components: {
    "tutorial-notification-image": SecondStepRegister,
    "tutorial-basic-info": FirstStepRegister,
    "tutorial-edit-complete": RegisterComplete,
    InviteFriendLine
  },
  metaInfo() {
    return {
      title: "プロフィール編集",
      titleTemplate: "%s | PreGo"
    };
  },
  data() {
    return {
      showTutorial: true,
      uploadVideo: 0,
      invite: null,
      poster: null,
      propsUser: {
        image_url: null,
        rank: null
      },
      result: null,
      imageTest: null,
      test: null,
      show: true,
      step: null,
      headerTitle: {
        text: "プロフィール編集",
        isShowTitle: true,
        isShowLogo: false,
        step: false
      },
      user: null,
      type: null,
      orther: null,
      sex: null
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "common/isLoading"
    })
  },
  async created() {
    this.$store.dispatch("common/setIsLoading", true);
    if (localStorage.getItem("step")) {
      this.step = localStorage.getItem("step");
    } else {
      this.step = 1;
    }
    this.user = this.$store.getters["auth/user"];
    if (this.user.sex) {
      this.sex = this.user.sex;
    }
    // this.invite = localStorage.getItem("add-friend-line");
    if (this.step === 1) {
      this.getStep(1);
    }
    if (this.user.registration_status > 1) {
      if (this.user.nickname) {
        if (this.user.sex === 1) {
          this.$router.push({ name: "Home" });
        } else {
          this.$router.push({ name: "CastHome" });
        }
      }
    }
    this.$store.dispatch("common/setIsLoading", false);
  },
  watch: {
    error() {
      let listOptions = document.getElementsByClassName("error");
      for (let i = 0; i < listOptions.length; i++) {
        if (listOptions[i].innerText != "") {
          let pos = listOptions[i].style.position;
          let top = listOptions[i].style.top;
          listOptions[i].style.position = "relative";
          listOptions[i].style.top = "-300px";
          listOptions[i].scrollIntoView({ behavior: "smooth", block: "start" });
          listOptions[i].style.top = top;
          listOptions[i].style.position = pos;
          return;
        }
      }
    }
  },
  methods: {
    async completeRegister() {
      this.$root.$refs.loading.start();
      this.getStep(3);
      await this.$store.dispatch("editProfile/updateProfile", {
        step: 10
      });
      this.$root.$refs.loading.finish();
    },
    resetAddFriendPrego() {
      localStorage.removeItem("add-friend-line");
      this.invite = null;
      // localStorage.setItem("step", 4);
      // this.step = 4;
      this.returnHome();
    },
    addFriendPrego() {
      window.open("https://line.me/R/ti/p/" + process.env.VUE_APP_LINE_ID);
      localStorage.removeItem("add-friend-line");
      this.invite = null;
      // localStorage.setItem("step", 4);
      // this.step = 4;
      this.returnHome();
    },
    returnHome() {
      localStorage.removeItem("step");
      document.scrollingElement.style.position = "static";
      document.scrollingElement.style.overflow = "auto";
      document.scrollingElement.style["overscroll-behavior"] = "auto";
      if (parseInt(this.sex) === 1) {
        this.$router.push({ name: "Home" });
      } else {
        this.$router.push({ name: "CastHome" });
      }
    },
    backTutorial() {
      if (this.user.login_type == "LINE") {
        if (this.step == 1) {
          this.step = 2;
          localStorage.setItem("step", this.step);
          return;
        }
      } else {
        if (this.step >= 2) {
          if (this.step == 11) {
            this.step = 4;
          } else {
            this.step = localStorage.getItem("step") - 1;
          }
          localStorage.setItem("step", this.step);
          return;
        }
      }
      this.$router.push({ name: "CastHome" });
    },
    tutorialBasicInfo(params) {
      this.$root.$refs.loading.start();
      this.$store
        .dispatch("editProfile/updateProfile", {
          step: 7,
          sex: params["sex"],
          date_of_birth: params["date"],
          nickname: params["nickname"],
          best_score:
            params["bestScore"] != "160以上" ? params["bestScore"] : 999,
          avg_score: params["avgScore"] != "180以上" ? params["avgScore"] : 999
        })
        .then(() => {
          this.$root.$refs.loading.finish();
          this.showTutorial = false;
          this.sex = params["sex"];
          let dataUser = {
            userId: this.user.user_id.toString(),
            field: {
              nickname: params["nickname"]
            }
          };
          this.$store.dispatch("firebase/updateUser", dataUser);
          this.getStep(2);
        });
    },
    tutorialEditComplete() {
      this.getStep(11);
    },
    checkStepTutorial() {
      if (this.step) {
        document.getElementById("edit-profile").style.position = "inherit";
        this.headerTitle.step = true;
      }
    },
    getStep(value) {
      localStorage.setItem("step", value);
      this.step = localStorage.getItem("step");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/cast/editProfile.scss";
@import "~@/assets/scss/_fontFamily.scss";
.border-input {
  border: 1px solid #464d77 !important;
  background-color: #464d77 !important;
  color: #ffffff !important;
  max-width: 100px;
}
.place-holder {
  color: #9c9c9c;
  top: 54px;
  left: 24px;
  right: 18px;
  font-size: 14px;
  font-weight: normal;
}

.add-friend {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  z-index: 2000;
}
.content-scroll {
  // overflow-y: scroll;
  padding-top: 40px;
  padding-bottom: 60px;
  overflow-x: hidden;
  // height: calc(100vh - 108px);
}

@media screen and (max-width: 360px) {
  .place-holder {
    font-size: 13px;
    font-weight: normal;
  }
}

@media screen and (min-width: 1200px) {
  .place-holder {
    top: 60px;
    left: 30px;
    font-size: 15px;
    font-weight: normal;
  }
  .content-scroll {
    padding-bottom: 100px;
  }
}

.modal-logout-title {
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.btn-logout {
  width: 155px;
  height: 45px;
  color: #ffffff;
  background-color: #464d77;
  font-size: 16px;
  border-radius: 5px;
}
.btn-cancel-logout {
  width: 155px;
  height: 45px;
  color: #000000;
  background-color: #e4e4e4;
  font-size: 16px;
  border-radius: 5px;
  margin-right: 10px;
}
</style>

<style lang="scss" scoped>
#edit-profile {
  /deep/.edit-profile-vue-cropper {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 100;
    background-color: #fff;
    max-width: 1080px;
    .upload-example-cropper {
      height: calc(100% - 90px);
    }
    .btn-cancel {
      background-color: #dbdbdb;
      border-radius: 5px;
      height: 45px;
      width: 163px;
      margin-right: 11px;
      .btn {
        width: 100%;
        height: 100%;
      }
    }
    .btn-success {
      background-color: #464d77;
      border-radius: 5px;
      height: 45px;
      width: 163px;
      .btn {
        color: #fff;
        width: 100%;
        height: 100%;
      }
    }
    .btn-option {
      background-color: white;
      position: relative;
      z-index: 10;
      padding: 20px 0;
    }
  }
}

@media screen and (min-width: 1080px) {
  #edit-profile {
    /deep/.edit-profile-vue-cropper {
      top: 0;
      .upload-example-cropper {
        height: calc(100% - 173px);
      }
      .btn-cancel {
        height: 55px;
        width: 210px;
        margin-right: 20px;
        font-size: 20px;
      }
      .btn-success {
        height: 55px;
        width: 210px;
        font-size: 20px;
      }
      .btn-option {
        padding: 35px 0;
      }
    }
  }
}
</style>
